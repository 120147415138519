@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paper-background {
  background-color: #f9f9f9; /* Light paper-like color */
  background-image: radial-gradient(#000000 0.5px, transparent 0.5px);
  background-size: 10px 10px;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Ensure the duck doesn't go outside the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
}

.bouncing-image {
  position: absolute;
  width: 250px; /* Adjust size as needed */
  height: auto;
}

h1 {
  font-family: 'Fira Mono', monospace; /* Apply the imported monospace font */
  font-size: 1000%; /* Increase the font size */
  color: #333; /* Set a text color (optional) */
  margin: 0; /* Remove default margin */
}


.boxes-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  gap: 20px; /* Adds space between boxes */
}

.box {
  width: 200px;
  height: 200px;
  background-color: #e0e0e0;
  border: 2px solid #aaa;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2),
              inset -5px -5px 10px rgba(255, 255, 255, 0.7);
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px; /* Optional: to make the corners rounded */
}

.box.highlight {
  border: 15px solid #00f;
}

.box.filled {
  border: 10px solid yellow;
}

.box::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 5px; /* Match the border radius of the box */
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2),
              inset -5px -5px 10px rgba(255, 255, 255, 0.7);
  pointer-events: none; /* Ensure it doesn't block mouse events */
}

.box.correct {
  border: 20px solid green;
}

.box.incorrect {
  border: 20px solid red;
}